import React from "react";
import Container from "../containers/Container";
import CardList from "../components/CardList";

export default ({ item }) => {
  let content = item.fields.content;
  if (
    !content &&
    item.fields.automaticContent &&
    item.fields.automaticContent.length
  ) {
    content = item.fields.automaticContent;
  }
  return (
    <Container paddedHorizontal background>
      <CardList list={content} style={item.fields.style} />
    </Container>
  );
};
