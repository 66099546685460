import React from "react";
import { RespImage, getUrl } from "../utils/RespImage";
import styled from "styled-components";
import { device } from "../utils/Device";
import { FadeOnScroll } from "./FadeUp";

const GalleryView = styled.div`
  line-height: 0;
  width: 100%;
  height: 100%;
  display: ${prop => (prop.totalItems > 1 ? "block" : "flex")};
  flex-direction: ${prop => (prop.totalItems > 1 ? "column" : "row")};

  img {
    object-fit: cover;
    box-sizing: border-box;
  }

  .gallery-bg {
    background-color: var(--color-line);
    @media ${device.mobile_up} {
      display: grid;
      min-height: 33vw;
    }
  }
  .landscape,
  .portrait {
    margin-bottom: var(--padding-x2);
  }
  .landscape {
    width: 100%;
    display: inline-block;
  }

  .portrait {
    display: inline-block;
    width: 100%;
    margin-bottom: var(--padding-x2);
    @media ${device.mobile_up} {
      width: 50%;
      padding-right: var(--padding-x);
      &.portrait-2nd {
        padding-left: var(--padding-x);
        padding-right: 0;
      }
    }
  }
  &.gallery--mobile {
    @media ${device.mobile_up} {
      display: none;
    }
  }
  &.gallery--desktop {
    @media ${device.mobile} {
      display: none;
    }
  }
`;

const Gallery = ({ gallery, className }) => {
  if (!gallery) return null;
  let currPortrait = -10;
  return (
    <GalleryView className={"gallery " + className} totalItems={gallery.length}>
      {gallery.map((image, index) => {
        const dimen = image.fields.file.details.image;
        const isPortrait = dimen.height >= dimen.width;
        const lastPortrait = currPortrait;
        let is2ndPortrait = isPortrait && lastPortrait === index - 1;
        currPortrait = isPortrait && !is2ndPortrait ? index : currPortrait;
        const widthVw = isPortrait ? 40 : 80;

        const className = [
          isPortrait ? "portrait" : "landscape",
          is2ndPortrait && "portrait-2nd"
        ]
          .filter(e => e)
          .join(" ");

        return (
          <div key={index} className={className}>
            <FadeOnScroll
              key={index + "," + getUrl(image)}
              timeout={{ appear: index * 200, enter: 200 }}
            >
              <div className={"gallery-bg"}>
                <RespImage image={image} widthVw={widthVw} />
              </div>
            </FadeOnScroll>
          </div>
        );
      })}
    </GalleryView>
  );
};

export default Gallery;
