import React from "react";
import { useRouteData } from "react-static";
//
import Container from "../../containers/Container";

import RichText from "../richText";
import List from "../list";
import TextImage from "../textImageBlock";

import { MetaData } from "../../components/MetaData";
import TextImageBlock from "../../components/TextImageBlock";
import TextMapBlock from "../../components/TextMapBlock";

export default function Page() {
  const { page } = useRouteData();
  return (
    <Container>
      <MetaData
        title={page.fields.title}
        metaData={page.fields.metaData && page.fields.metaData.fields}
      />

      {page.fields.items &&
        page.fields.items.map((item, index) => {
          let contentType = item.sys.contentType.sys.id;
          console.log(contentType);
          switch (contentType) {
            case "richText":
              return <RichText item={item} key={index} />;
            case "list":
              return <List item={item} key={index} />;
            case "textImageBlock": {
              // if (item.fields.name === "contact-main") {
              //   return (
              //     <TextImageBlock
              //       key={index}
              //       text={item.fields.text}
              //       element={(props) => <div></div>}
              //     />
              //   );
              // }
              return <TextImage item={item} key={index} />;
            }
            case "mapImageBlock":
              return <TextMapBlock key={index} item={item} />;
            default:
              return null;
          }
        })}
    </Container>
  );
}
