import React, { Component } from "react";
import { Head } from "react-static";
import { getUrl } from "../utils/RespImage";
import * as PropTypes from "prop-types";

export class MetaData extends Component {
  render() {
    let { title, metaData } = this.props;
    if (!metaData) {
      return (
        <Head>
          <title>{title}</title>
        </Head>
      );
    }
    return (
      <Head>
        {metaData.title ? <title>{metaData.title}</title> : null}
        {metaData.description ? (
          <meta name="description" content={metaData.description} />
        ) : null}
        {metaData.keywords ? (
          <meta name="keywords" content={metaData.keywords} />
        ) : null}
        {metaData.image ? (
          <meta property="og:image" content={getUrl(metaData.image)} />
        ) : null}
      </Head>
    );
  }
}

MetaData.propTypes = {
  title: PropTypes.any,
  metaData: PropTypes.any
};
