import React, { useState, useEffect } from "react";
import Container from "../containers/Container";
import RichText from "../utils/RichText";
import Gallery from "../components/Gallery";
import { CSSTransition } from "react-transition-group";
import CardList from "./CardList";
import styled from "styled-components";
import { device } from "../utils/Device";
import { useLocation } from "react-static";

export default ({ text, images, imagesDesktop, list, element }) => {
  const [stickyAlign, setStickyAlign] = useState("top");

  useEffect(() => {
    setTimeout(() => {
      if (window && window.document) {
        let NAV_HEIGHT = 80;
        let subLinks = window.document.getElementById("sticky-scroll");
        const heightSticky = subLinks && subLinks.offsetHeight;
        const heightViewPort = window.innerHeight - NAV_HEIGHT;
        if (heightSticky > heightViewPort && stickyAlign !== "bottom") {
          setStickyAlign("bottom");
        } else if (stickyAlign !== "top") {
          setStickyAlign("top");
        }
      }
    }, 150);
  }, [useLocation()]);

  return (
    <Scroller split paddedHorizontal stickyAlign={stickyAlign}>
      <Container className="project-info rich-text">
        <div id="sticky-scroll" className="project-info-text">
          <CSSTransition
            in={true}
            key={text.substr(0, 10)}
            timeout={{
              enter: 500
            }}
            appear={true}
            classNames="fadeup"
          >
            <RichText className={"fadeup-initial"} html={text} />
          </CSSTransition>
        </div>
      </Container>

      {images || imagesDesktop ? (
        <section className="project-content">
          {images && <Gallery gallery={images} className={"gallery--mobile"} />}
          {imagesDesktop && (
            <Gallery gallery={imagesDesktop} className={"gallery--desktop"} />
          )}
        </section>
      ) : null}

      {list ? (
        <section className="project-content">
          <CardList list={list.fields.content} style={list.fields.style} />
        </section>
      ) : null}

      {element ? element : null}
    </Scroller>
  );
};

const Scroller = styled(Container)`
  .project-info {
    padding-bottom: var(--padding-x2);
  }
  .project-info-text {
    padding-top: var(--padding-x2_double);
  }

  > *:nth-child(1) {
    @media ${device.tablet_up} {
      align-items: ${props =>
        props.stickyAlign === "top" ? "flex-start" : "flex-end"};
    }
  }

  @media only screen and (min-width: 768px) {
    .project-info-text p:last-of-type {
      margin-bottom: 0;
    }

    .project-info-text {
      position: -webkit-sticky;
      position: sticky;
      ${props =>
        props.stickyAlign === "top"
          ? "top: calc( var(--padding-x2) + 50px );"
          : "bottom: var(--padding-x2);"};
      padding-top: 50px;
    }
  }
`;
