import React from "react";
import TextImageBlock from "../components/TextImageBlock";

export default ({ item }) => {
  if (!item) {
    return null;
  }
  return (
    <TextImageBlock
      text={item.fields.text}
      images={item.fields.imagesMobile}
      imagesDesktop={item.fields.images}
      list={item.fields.list}
    />
  );
};
