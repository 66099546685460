import React from "react";
import Container from "../containers/Container";
import RichText from "../utils/RichText";
import { CSSTransition } from "react-transition-group";

export default ({ item }) => {
  if (!item || !item.fields.content) {
    return null;
  }
  return (
    <Container maxWidth={"600px"} align={"left"} padded paddedVerticalDouble>
      <CSSTransition
        in={true}
        key={item.fields.content.substr(0, 10)}
        timeout={{
          appear: 0
        }}
        appear={true}
        classNames="fadeup"
      >
        <RichText
          className={"rich-text fadeup-initial"}
          html={item.fields.content}
        />
      </CSSTransition>
    </Container>
  );
};
