import React, { useState } from "react";
import OnVisible, { setDefaultProps } from "react-on-visible";
import { CSSTransition } from "react-transition-group";

setDefaultProps({
  bounce: false,
  visibleClassName: "visible",
  percent: 5
});

export const FadeOnScroll = ({ children, ...transitionProps }) => {
  const [isVisible, setvisible] = useState(false);

  return (
    <OnVisible onChange={val => setvisible(val)} className={"visibility-el"}>
      <CSSTransition
        in={isVisible}
        appear={true}
        classNames="fadeup"
        timeout={200}
        {...transitionProps}
      >
        <div className={"fadeup-initial"}>{children}</div>
      </CSSTransition>
    </OnVisible>
  );
};
