//"https://maps.googleapis.com/maps/api/js?key=AIzaSyC3rTpP02Da-rB7le4Ygg8Z3rJg9IYF_lE&callback=initMap"
import React, {useCallback} from "react";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import styled from "styled-components";


// class MapSection extends Component{
//   _mapLoaded(mapProps, map) {
//     map.setOptions({
//       styles: mapStyle
//    });
//   }

//   render() {
//     return <MapFrame>
//       <Map 
//         google={this.props.google} 
//       />
//     </MapFrame>;
//   }
// }

const MapSection = (props) => {
  console.log(props);

  const mapLoadedHandler = useCallback((mapProps, map) => {
    map.setOptions({
      styles: mapStyle
    });
  });

  return (
    <div className="map-wrapper">
      <MapFrame>
        <Map
          google={props.google}
          initialCenter={{lat: props.mapDetails.mapPinLocation.lat, lng: props.mapDetails.mapPinLocation.lon}}
          zoom={props.mapDetails.zoomLevel}
          onReady={(mapProps, map) => mapLoadedHandler(mapProps, map)}
        >
          <Marker />
        </Map>
      </MapFrame>
    </div>
  );
};

const MapFrame = styled.div`
  min-height: 600px;
  height: var(--body-available-height);
`;

export default GoogleApiWrapper({
  apiKey: "AIzaSyC3rTpP02Da-rB7le4Ygg8Z3rJg9IYF_lE"
})(MapSection);

const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5"
      }
    ]
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161"
      }
    ]
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5"
      }
    ]
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575"
      }
    ]
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161"
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5"
      }
    ]
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  }
];
